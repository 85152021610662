<template>
  <div class="warp">
    <div class="ipt">
      <el-input v-model="pwd" type="password" placeholder="请输入旧密码"></el-input>
      <el-input v-model="newPwd" type="password" placeholder="请输入新密码"></el-input>
      <el-input v-model="confirmPwd" type="password" placeholder="请确认新密码"></el-input>
      <el-button type="primary" :loading="loding" @click="handleLogin" round>确定</el-button>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import { mapActions } from 'vuex'
import crypto from 'crypto'
import ShInput from '@/components/uikit/input'
import ShButton from '@/components/uikit/button'

export default {
  name: 'Login',
  components: {
    // ShInput,
    // ShButton
  },
  data() {
    return {
      pwd: '',
      newPwd: '',
      confirmPwd: '',
      loding: false
    }
  },
  methods: {
    ...mapActions([
      'modifyPwd'
    ]),
    handleLogin() {
      if(this.newPwd !== this.confirmPwd) {
        Message.error('两次输入的密码不一致')
        return
      }
      this.loding = true
      let hash = crypto.createHash('sha256')
      let hash2 = crypto.createHash('sha256')
      let old_password = hash.update(this.pwd).digest('hex')
      let new_password = hash2.update(this.newPwd).digest('hex')
      const params = {
        old_password,
        new_password
      }
      this.modifyPwd(params).then((res) => {
        this.loding = false
        this.$router.push({
          name: 'login'
        })
      }).catch(err => {
        this.loding = false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.warp {
  width: 100%;
  // height: calc(100% - 246px);
  padding: 200px 0;
  .ipt {
    width: 600px;
    padding: 80px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #182760;
    margin: 0 auto;
    &>div {
      margin: 5px 0;
    }
    button {
      width: 100%;
      margin-top: 20px;
    }
  }
}
@media (max-width: 767px) {
  .warp {
    padding: 200px 20px;
    .ipt {
      width: 100%;
    }
  }
}
</style>
